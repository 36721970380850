import wordlyPreview from './wordlyPreview.jpg'



const Home = () =>{

    return (
        <div className='container home-page-div'>
            <h1>Projects</h1>
            <div className='apps-div row align-items-start'>
            <div className="single-app-div col">
                <a href="https://wordlyapp.netlify.app/" className='app-link' rel="noreferrer" target="_blank">
                    <img src={wordlyPreview} className='app-image' alt="" height="400" width="275" />
                    <h2 className='app-title'>Wordly</h2>
                </a>
                </div>
            
            </div>
        </div>
    )
}

export default Home